export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"name":"keywords","content":"cypress, cypress.io, Filip Hric, course, workshop, how to, tutorial"},{"name":"image","content":"https://og.filiphric.com/api/og?image"},{"property":"og:url","content":"https://filiphric.com/"},{"property":"og:image","content":"https://og.filiphric.com/api/og?image"},{"property":"twitter:card","content":"summary_large_image"},{"property":"twitter:creator","content":"@filip_hric"},{"property":"twitter:image","content":"https://og.filiphric.com/api/og?image"}],"link":[{"rel":"icon","type":"image/x-icon","href":"/favicon.ico"},{"rel":"apple-touch-icon","sizes":"192x192","href":"/android-chrome-192x192.png"},{"rel":"apple-touch-icon","sizes":"512x512","href":"/android-chrome-512x512.png"},{"rel":"alternate","type":"application/rss+xml","title":"Blog RSS Feed","href":"/rss.xml"}],"style":[],"script":[],"noscript":[],"htmlAttrs":{"lang":"en"},"titleTemplate":"%s Filip Hric"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"